// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD2IrtiVIQDOQJr7iPfWumDeLiKWsjRYYk",
  authDomain: "murimi-shop.firebaseapp.com",
  projectId: "murimi-shop",
  storageBucket: "murimi-shop.appspot.com",
  messagingSenderId: "134111887113",
  appId: "1:134111887113:web:fe0d8580f839c699691dd8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;