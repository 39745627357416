import Product from "./pages/Product";
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import FlutterWave from "./components/FlutterWave";
import Success from "./pages/Success";

const App = () => {
  const user = useSelector((state) => state.user.currentUser);

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <Redirect to="/login" />;
    }

    return children;
  };
  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>
        <Route path="/register">
          {user ? <Redirect to="/" /> : <Register />}
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/category/:category">
          <ProductList />
        </Route>
        <Route path="/products/:id">
          <Product />
        </Route>
        <Route path="/payment">
          <FlutterWave />
        </Route>
        <ProtectedRoute>
          <Route path="/cart">
            <Cart />
          </Route>
        </ProtectedRoute>

        <Route path="/success">
          <Success />
        </Route>

      </Switch>
    </Router>
  );
};

export default App;
