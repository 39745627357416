import React from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useSelector } from 'react-redux';

export default function FlutterWave() {
  const cart = useSelector((state) => state.cart);
  const currentUser = useSelector((state) => state.user.currentUser);

  const config = {
    public_key: 'FLWPUBK_TEST-8f63f12113a5fc1e4c335b1ebb100b00-X',
    tx_ref: Date.now(),
    amount: `${cart.total}`,
    currency: 'USD',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: `${currentUser.email}`,
      phonenumber: '0783783782',
      name: `${currentUser.fullname}`,
    },
    customizations: {
      title: 'Payment to MURIMI247',
      description: 'Payment for items in cart',
      logo: 'https://murimi247.com/wp-content/uploads/2021/01/farming_in_bindura_murimi-247-logo.png',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <div className="App">
      <button
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
               console.log(response);
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
          });
        }}
      >
        Payment with Flutterwave
      </button>
    </div>
  );
}