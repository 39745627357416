import styled from "styled-components";
import { categories } from "../data";
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({ padding: "0px", flexDirection: "column" })}
`;
const ContainerReal = styled.div`
  display: flex;
flex-direction: column;
  ${mobile({ padding: "0px", flexDirection: "column" })}
`;
const TitleCat = styled.h1`
background-color: #badc58;
color: white;
text-align: center;
text-transform: uppercase;
justify-content: center;
`     

const Categories = () => {
  return (
    <ContainerReal>
      <TitleCat>Popular Categories</TitleCat>
      <Container>
        {categories.map((item) => (
          <CategoryItem item={item} key={item.id} />
        ))}
      </Container>
    </ContainerReal>
  );
};

export default Categories;
