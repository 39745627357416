import { Badge } from "@material-ui/core";
import { Search, ShoppingCartOutlined } from "@material-ui/icons";
import React, { useContext } from "react";
import "./navbar.css"
import styled from "styled-components";
import { mobile } from "../responsive";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../redux/userRedux";
const Container = styled.div`
  height: 60px;
  ${mobile({ height: "50px" })}
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ padding: "10px 0px" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Language = styled.span`
  font-size: 14px;
  cursor: pointer;
  ${mobile({ display: "none" })}
`;

const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
`;

const Input = styled.input`
  border: none;
  ${mobile({ width: "50px" })}
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
`;
const Button = styled.button`
`;

const Logo = styled.h1`
  font-weight: bold;
  ${mobile({ fontSize: "24px" })}
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ flex: 2, justifyContent: "center" })}
`;

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;

  margin-left: 25px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;
const MurimiLogo = styled.img`
  width: 100px;
  height: 50px;
`;



const Navbar = () => {
  const history = useHistory();
  const handleLogoumt = (e)=> {
    e.preventDefault();
    // localStorage.clear();
    history.push("/");

  };
  const handleLogout = (history,e) => (dispatch) => {
    e.preventDefault();
    localStorage.removeItem('persist:root');
    localStorage.removeItem('persist:root');
    localStorage.clear();

    console.log('Made it here');
    dispatch(logout());
    history.push('/login');
};

  const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  const currentUser = user && JSON.parse(user).currentUser;
  const username = currentUser?.fullname;
  const quantity = useSelector((state) => state.cart.quantity);
  // console.log(quantity);
  return (
    <Container>
      <Wrapper>
        <Left>
          {/* <Language>EN</Language>
          <SearchContainer>
            <Input placeholder="Search" />
            <Search style={{ color: "gray", fontSize: 16 }} />
          </SearchContainer> */}
          <Link to="/">
            <MurimiLogo src="https://murimi247.com/wp-content/uploads/2021/01/farming_in_bindura_murimi-247-logo.png" />
          </Link>
        </Left>
        <Center>
          <Logo>MURIMI 247</Logo>
        </Center>
        <Right>
          {currentUser ? (
            username
          ) : (
            <>
              {" "}
              <Link to="/register">
                <MenuItem>REGISTER</MenuItem>
              </Link>
              <Link to="/login">
                <MenuItem>SIGN IN</MenuItem>
              </Link>
            </>
          )}
          <Link to="/cart">
            <MenuItem>
              <Badge badgeContent={quantity} color="primary">
                <ShoppingCartOutlined />
              </Badge>
            </MenuItem>
          </Link>
          { currentUser &&<MenuItem>
            <Button className="button" onClick={handleLogout}>
              Logout
            </Button>
          </MenuItem>}
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
