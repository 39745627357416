export const sliderItems = [
    {
      id: 1,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/pestcides.jpeg?alt=media&token=e4104d27-fda5-4514-85cc-5b9274ddfd59",
      title: "PESTCIDES",
      desc: "DON'T COMPROMISE ON FARMING! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "f5fafd",
      cat: "pesticides"
    },
    {
      id: 2,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/Herbicide%20(2).jpg?alt=media&token=ffda390d-e242-45d2-9c83-4a3027b644d8",
      title: "HERBICIDES",
      desc: "DON'T COMPROMISE ON FARMING! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "fcf1ed",
      cat: "herbicides"
    },
    {
      id: 3,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/Seeds.jpg?alt=media&token=988018d7-86aa-4e6d-aa78-6741756f12ed",
      title: "SEEDS",
      desc: "DON'T COMPROMISE ON FARMING! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "fbf0f4",
      cat: "seeds"
      
    },
    {
      id: 4,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/Fertilizer.jpg?alt=media&token=af1ce338-a336-4c10-b6f7-fc137e2e800e",
      title: "FERTILISER",
      desc: "DON'T COMPROMISE ON FARMING! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "fbf0f4",
      cat: "fertilizer"
      
    },
  ];

  export const categories = [
    {
      id: 1,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/pestcides.jpeg?alt=media&token=e4104d27-fda5-4514-85cc-5b9274ddfd59",
      title: "PESTCIDES",
      cat: "pesticides"
    },
    {
      id: 2,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/Herbicide%20(2).jpg?alt=media&token=ffda390d-e242-45d2-9c83-4a3027b644d8",
      title: "HERBICIDES",
      cat: "herbicides"
    },
    {
      id: 3,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/Seeds.jpg?alt=media&token=988018d7-86aa-4e6d-aa78-6741756f12ed",
      title: "SEEDS",
      cat: "seeds"
    },
    {
      id: 4,
      img: "https://firebasestorage.googleapis.com/v0/b/murimi-shop.appspot.com/o/Fertilizer.jpg?alt=media&token=af1ce338-a336-4c10-b6f7-fc137e2e800e",
      title: "FERTILISER",
      cat: "fertilizer"
      
    },
  ];

  export const popularProducts = [
    {
      id:1,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
    {
      id:2,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
    {
      id:3,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
    {
      id:4,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
    {
      id:5,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
    {
      id:6,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
    {
      id:7,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
    {
      id:8,
      img:"https://www.biological-insecticide.com/photo/pc95227961-biological_maize_crop_pesticides_acnpv_killing_agricultural_pests_on_vegetable_fruit.jpg",
    },
  ]