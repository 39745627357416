import { loginFailure, loginStart, loginSuccess, registerFailure, registerStart, registerSuccess } from "./userRedux";
import {publicRequest, userRequest} from "../services/config"
import { orderFailure, orderStart, orderSuccess } from "./orderRedux";
import { emptyCart } from "./cartRedux";

export const login = async(dispatch, user)=>{
    dispatch(loginStart());
    try {
        const res = await publicRequest.post("auth/login", user)
        dispatch(loginSuccess(res.data))
        
    } catch (error) {
        dispatch(loginFailure())
    }
};
export const register = async (user, dispatch) => {
    dispatch(registerStart());
    try {
      const res = await publicRequest.post(`/auth/register`,  user );
      dispatch(registerSuccess(res.data));
    } catch (error) {
      dispatch(registerFailure());
    }
}
export const addOrder = async (order, dispatch) => {
  dispatch(orderStart());
  try {
    const res = await userRequest.post(`/orders`,  order );
    dispatch(orderSuccess(res.data));
    emptyCart();
  } catch (error) {
    dispatch(orderFailure());
  }
};