import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";
// import {
// 	surfaceProResponsive,
// 	responsive988,
// 	responsive948,
// 	responsive908,
// 	ipadAirResponsive,
// 	ipadMiniResponsive,
// 	miniPhoneResponsive,
// 	miniPhoneResponsive715,
// 	miniPhoneResponsive685,

// } from "../responsive";
const Container = styled.div`
  flex: 1;
  margin: 3px;
  height: 70vh;
  position: relative;
  position: relative;
  background-color: #f0ecec;
  width: 200px;
  height: 300px;
  padding: 5px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.05);
  }
  a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
 }

  ${mobile({
    width: "100vw",
    height: "350px",
  })}
`;

const Image = styled.img`
  object-fit: cover;
  width: 90%;
  height: 200px;
  object-fit: cover;
  text-decoration: none;

  border-radius: 5px;
  overflow: hidden;
  ${mobile({ height: "20vh" })}
`;

const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
  margin-bottom: 10px;
`;

const Button = styled.button`
  border: none;
  padding: 10px;
  background-color: #18c020;
  color: white;
  cursor: pointer;
  font-weight: 600;
  margin-top: 150px;
`;

const CategoryItem = ({ item }) => {
  const location = useLocation();
  // console.log(location.pathname.split('/')[2]);
  return (
    <Container>
      <Link to={`category/${item.cat}`}>
        <Title>{item.title}</Title>
        <Info></Info>
        <Image src={item.img} />
        <Info>
          <Button>SHOP NOW</Button>
        </Info>
      </Link>
    </Container>
  );
};

export default CategoryItem;
