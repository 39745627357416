import { useEffect, useState } from "react";
import styled from "styled-components";
import { publicRequest } from "../services/config";
import Product from "./Product";
import Loader from "../components/Loader"
const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const ContainerProd = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleProd = styled.h1`
  background-color: #badc58;
  color: white;
  text-align: center;
  text-transform: uppercase;
left: 0;
right: 0;
`;

const Products = ({ cat, filters, sort }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await publicRequest.get(
          cat ? `/products?category=${cat}` : "/products"
        );
        // console.log(res)
        setProducts(res.data.data.products);
      } catch (error) {}
    };
    getProducts();
  }, [cat]);

  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);
  return (
    <ContainerProd>
      <TitleProd> Popular Products</TitleProd>
      {products?<Container>
        {cat
          ? filteredProducts.map((item) => (
              <Product item={item} key={item.id} />
            ))
          : products
              .slice(0, 50)
              .map((item) => <Product item={item} key={item.id} />)}
      </Container>:Loader}
    </ContainerProd>
  );
};

export default Products;
