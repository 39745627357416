// import { toast } from "react-toastify";
import { emptyCart, removeProduct } from "./cartRedux";

export const deleteProductById = (id) => (dispatch) => {
	dispatch(removeProduct(id));
};

export const deleteCart = () => (dispatch) => {
	if (window.confirm("Are you SURE?")) {
		dispatch(emptyCart());
		// toast.success("Cart items cleared", { theme: "colored" });
	}
};
